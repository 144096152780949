import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from "./context/AuthContext";
import PrivateWrapper from "./components/PrivateWrapper";
import NotFoundPage from './pages/NotFoundPage';
import { Navigate } from 'react-router-dom';
import { CustomToaster } from './components/CustomToast';

import VerifyAuthPage from './pages/VerifyAuthPage';
import ChatPage from './pages/chat/ChatPage';
import PublicWrapper from './components/PublicWrapper';

const App = () => {
  return (
    <>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/chat" element={<PrivateWrapper><ChatPage /></PrivateWrapper>} />
            <Route path="/login" element={<PublicWrapper><ChatPage /></PublicWrapper>} />
            <Route path="/auth/verify" element={<VerifyAuthPage />} />
            <Route path="/" element={<Navigate replace to="/chat" />} />
            <Route path="*" element={<NotFoundPage />} /> {/* 404 */}
          </Routes>
        </Router>
      </AuthProvider>
      <CustomToaster />
    </>
  );
}

export default App;
