import React, { useEffect, useContext } from 'react';
import { useAuth } from '../context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { Loader2 } from 'lucide-react';

const PrivateWrapper = ({ children }) => {
  const location = useLocation();
  const { user, isLoading } = useAuth();

  console.log('PrivateWrapper:', user);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-white dark:bg-gray-900">
        <Loader2 size={50} className="animate-spin text-gray-600 dark:text-white" />
      </div>
    );
  }

  return user ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateWrapper;
