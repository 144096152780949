import React from "react";
import useDocumentTitle from "../utils/pageTitle";

const Page = ({ children, title }) => {
    const titleSuffix = " - Preggo AI";
    const combinedTitle = title ? `${title}${titleSuffix}` : `Preggo AI`;
    useDocumentTitle(combinedTitle)

    return (
        <>
            {children}
        </>
    );
};

export default Page;
