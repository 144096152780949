import React, { useState, useEffect, useRef, Fragment } from 'react';
import axios from '../../utils/axios';
import { Menu, Dialog, Transition } from '@headlessui/react';
import { MoreHorizontal, Trash, Share, Pencil, AlertTriangle } from 'lucide-react';

const RenameModal = ({ isOpen, onClose, conversation, onRename }) => {
  const [newTitle, setNewTitle] = useState(conversation ? conversation.title : '');

  useEffect(() => {
    if (conversation) {
      setNewTitle(conversation.title);
    }
  }, [conversation]);

  const handleRename = () => {
    onRename(newTitle);
    onClose();
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 dark:bg-opacity-50" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                Rename Conversation
              </Dialog.Title>
              <div className="mt-4">
                <input
                  type="text"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                  className="w-full p-2 border rounded mb-4 dark:bg-gray-700 dark:border-gray-600 dark:text-white outline-none border-none focus:ring-teal-500"
                />
              </div>

              <div className="mt-4 flex justify-end space-x-2">
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-teal-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-white bg-teal-500 rounded-md hover:bg-teal-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-teal-500"
                  onClick={handleRename}
                >
                  Rename
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const DeleteConfirmModal = ({ isOpen, onClose, conversation, onDelete }) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 dark:bg-opacity-50" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white flex items-center">
                <AlertTriangle className="w-6 h-6 text-red-500 mr-2" />
                Confirm Deletion
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Are you sure you want to delete the conversation "{conversation?.title}"? This action is permanent and cannot be undone. All data associated with this conversation will be permanently removed from our servers.
                </p>
              </div>

              <div className="mt-4 flex justify-end space-x-2">
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                  onClick={() => {
                    onDelete(conversation.id);
                    onClose();
                  }}
                >
                  Delete Permanently
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const RecentConversationsList = ({ onSelectConversation, selectedConversation }) => {
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [renameModalOpen, setRenameModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [conversationToRename, setConversationToRename] = useState(null);
  const [conversationToDelete, setConversationToDelete] = useState(null);
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('/conversation');
        setConversations(response.data);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching conversations:', err);
        setError('Failed to load conversations. Please try again later.');
        setIsLoading(false);
      }
    };

    fetchConversations();
  }, [selectedConversation]);

  const handleConversationClick = (event, conversationId) => {
    if (menuButtonRef.current && !menuButtonRef.current.contains(event.target)) {
      onSelectConversation(conversationId);
    }
  };

  const deleteConversation = async (conversationId) => {
    try {
      await axios.delete(`/conversation/${conversationId}`);
      setConversations(prevConversations => {
        // Create a new array of sections
        const updatedSections = prevConversations.map(section => ({
          ...section,
          conversations: section.conversations.filter(convo => convo.id !== conversationId)
        }));

        // Remove any sections that no longer have conversations
        const nonEmptySections = updatedSections.filter(section => section.conversations.length > 0);

        return nonEmptySections;
      });

      // If the deleted conversation was the selected one, clear the selection
      if (selectedConversation === conversationId) {
        onSelectConversation(null);
      }
    } catch (err) {
      console.error('Error deleting conversation:', err);
    }
  };

  const openRenameModal = (conversationId) => {
    const convo = conversations.flatMap(section => section.conversations).find(c => c.id === conversationId);
    setConversationToRename(convo);
    setRenameModalOpen(true);
  };

  const openDeleteModal = (conversationId) => {
    const convo = conversations.flatMap(section => section.conversations).find(c => c.id === conversationId);
    setConversationToDelete(convo);
    setDeleteModalOpen(true);
  };

  const handleRename = async (newTitle) => {
    if (!conversationToRename) return;
    try {
      await axios.patch(`/conversation/${conversationToRename.id}`, { title: newTitle });
      setConversations(prevConversations => 
        prevConversations.map(section => ({
          ...section,
          conversations: section.conversations.map(convo =>
            convo.id === conversationToRename.id ? { ...convo, title: newTitle } : convo
          )
        }))
      );
      setRenameModalOpen(false);
      setConversationToRename(null);
    } catch (err) {
      console.error('Error renaming conversation:', err);
    }
  };

  const renderMenuItems = (conversationId) => [
    {
      icon: Share,
      text: 'Share',
      action: () => {
        console.log(`Sharing conversation ${conversationId}`);
        // Implement share functionality here
      }
    },
    {
      icon: Pencil,
      text: 'Rename',
      action: () => {
        console.log(`Renaming conversation ${conversationId}`);
        openRenameModal(conversationId);
      }
    },
    {
      icon: Trash,
      text: 'Delete',
      action: () => {
        console.log(`Deleting conversation ${conversationId}`);
        openDeleteModal(conversationId);
      }
    }
  ];

  if (isLoading) {
    return <div className="text-center py-4">Loading conversations...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-500">{error}</div>;
  }

  return (
    <div className="mb-4">
      {conversations.map((section) => (
        <div key={section.title} className="pt-4">
          <div className="flex items-center justify-between">
            <h2 className="text-xs mt-3 mb-2 font-bold ml-2 text-gray-600 dark:text-gray-400">{section.title}</h2>
          </div>
          {section.conversations.map((convo) => (
            <div
              key={convo.id}
              className={`relative group px-2.5 py-2 mb-1.5 rounded-xl cursor-pointer transition-colors duration-200 ${
                selectedConversation === convo.id || openMenuId === convo.id
                  ? 'bg-teal-100 dark:bg-gray-700 text-gray-800 dark:text-white'
                  : 'hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <div onClick={(event) => handleConversationClick(event, convo.id)}>
                <h3 className="font-semibold text-xs">{convo.title}</h3>
                <p className="text-xs text-gray-600 dark:text-gray-400 truncate">{convo.lastMessage}</p>
              </div>
              <Menu ref={menuRef} as="div" className={`${selectedConversation === convo.id || openMenuId === convo.id ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'} absolute h-full top-0 right-0 flex items-center pr-3 pl-5 rounded-r-xl bg-gradient-to-r from-transparent via-gray-100 to-gray-100 dark:via-gray-700 dark:to-gray-700 transition-opacity duration-200`}>
                <div className="h-full">
                  <Menu.Button ref={menuButtonRef} className="h-full text-gray-400 hover:text-gray-600 dark:text-gray-400 dark:hover:text-white">
                    <MoreHorizontal className="w-5 h-5" />
                  </Menu.Button>
                  <Menu.Items className="z-50 absolute top-0 right-0 translate-y-1/4 mt-2 w-32 bg-white dark:bg-gray-600 rounded-xl shadow-lg">
                    {renderMenuItems(convo.id).map((item, index) => (
                      <Menu.Item key={index}>
                        {({ active }) => (
                          <button
                            className={`flex first:pt-3.5 last:pb-3.5 first:rounded-t-xl last:rounded-b-xl items-center font-medium space-x-2 w-full text-left px-4 py-2 text-sm ${active ? 'bg-gray-100 dark:bg-gray-700' : ''}`}
                            onClick={item.action}
                          >
                            <item.icon size={16} />
                            <span>{item.text}</span>
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </div>
              </Menu>
            </div>
          ))}
        </div>
      ))}
      <RenameModal
        isOpen={renameModalOpen}
        onClose={() => {
          setRenameModalOpen(false);
          setConversationToRename(null);
        }}
        conversation={conversationToRename}
        onRename={handleRename}
      />
      <DeleteConfirmModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setConversationToDelete(null);
        }}
        conversation={conversationToDelete}
        onDelete={deleteConversation}
      />
    </div>
  );
};

export default RecentConversationsList;