import React, { useState, useEffect } from 'react';
import { Sun, Moon, Menu, SquarePen, LogOut, ArrowLeftFromLine, ArrowRightFromLine } from 'lucide-react';
import ChatInterface from './ChatInterface';
import RecentConversationsList from './RecentConversationsList';
import LoginModal from '../../components/LoginModal';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const ChatPage = () => {
    const [selectedConversation, setSelectedConversation] = useState(null);
    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const [isDesktopSidebarOpen, setIsDesktopSidebarOpen] = useState(true);
    const [isDarkMode, setIsDarkMode] = useState(false);

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    const { user, clearSession } = useAuth();

    const openLoginModal = () => setIsLoginModalOpen(true);
    const closeLoginModal = () => setIsLoginModalOpen(false);

    const newConversation = (conversation) => {
        console.log('New conversation:', conversation);
        setSelectedConversation(conversation);
    };

    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/login') {
            setIsLoginModalOpen(true);
            setIsDesktopSidebarOpen(false);
        }
    }, [location]);

    useEffect(() => {
        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark');
            setIsDarkMode(true);
        } else {
            document.documentElement.classList.remove('dark');
            setIsDarkMode(false);
        }
    }, []);

    const toggleDarkMode = () => {
        if (isDarkMode) {
            document.documentElement.classList.remove('dark');
            localStorage.theme = 'light';
        } else {
            document.documentElement.classList.add('dark');
            localStorage.theme = 'dark';
        }
        setIsDarkMode(!isDarkMode);
    };

    const toggleSidebar = () => setIsMobileSidebarOpen(!isMobileSidebarOpen);

    const newChat = () => setSelectedConversation(null);

    return (
        <>
            <div className="fixed flex h-full w-full bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200">
                {/* Sidebar for larger screens, slide-out for mobile */}
                <div className={`
                    bg-white dark:bg-gray-800 
                    ${isMobileSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
                    transition-transform duration-300 ease-in-out
                    fixed inset-y-0 left-0 z-30 w-72
                    ${isDesktopSidebarOpen ? 'md:translate-x-0' : 'md:-translate-x-full'}
                    border-r border-gray-200 dark:border-gray-700
                    h-full
                `}>
                    <div className="flex flex-col h-full">
                        {/* <div className="p-4"> */}
                            <div className="flex justify-between items-center p-4 pl-6 border-b border-gray-200 dark:border-gray-700">
                                <h1 className="text-2xl font-bold text-teal-500 dark:text-teal-300">Preggo AI</h1>
                                {/* New Chat Button */}
                                <button onClick={newChat} className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
                                    <SquarePen size={20} />
                                </button>
                            </div>
                        {/* </div> */}

                        <div className="flex-1 px-4 overflow-y-auto">
                                {/* Side nav content */}
                                <RecentConversationsList
                                    onSelectConversation={(id) => {
                                        setSelectedConversation(id);
                                        setIsMobileSidebarOpen(false);
                                    }}
                                    selectedConversation={selectedConversation}
                                />
                        </div>

                        <div className="p-4 border-t border-gray-200 dark:border-gray-700">
                            <div className="flex justify-between items-center">
                                <div>
                                    {/* Close side bar button */}
                                    <button onClick={() => setIsDesktopSidebarOpen(false)} className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
                                        <ArrowLeftFromLine size={20} />
                                    </button>
                                    {/* Dark mode toggle */}
                                    <button onClick={toggleDarkMode} className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
                                        {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
                                    </button>
                                </div>
                                {/* Log out button */}
                                <button onClick={clearSession} className="py-2 px-3 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
                                    <span>Log out</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main content area */}
                <div className={`flex-1 flex flex-col w-full ${isDesktopSidebarOpen ? 'md:ml-72' : 'md:ml-0'}`}>
                    {/* Top bar for mobile */}
                    <div className="md:hidden flex items-center justify-between p-4 bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700">
                        <button onClick={toggleSidebar} className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
                            <Menu size={24} />
                        </button>
                        <h1 className="text-xl font-bold text-teal-500 dark:text-teal-300">Preggo AI</h1>
                        <button onClick={newChat} className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-150 ease-in-out">
                            <SquarePen size={24} />
                        </button>
                    </div>

                    {/* Chat interface */}
                    <div className="flex-1 overflow-hidden">
                        <ChatInterface conversationId={selectedConversation} onNewConversation={newConversation} />
                    </div>
                </div>

                {/* Overlay for mobile when sidebar is open */}
                {isMobileSidebarOpen && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-50 z-20 md:hidden"
                        onClick={toggleSidebar}
                    ></div>
                )}

                {/* Button to open sidebar on desktop */}
                {!isDesktopSidebarOpen && (
                    <button
                        onClick={() => setIsDesktopSidebarOpen(true)}
                        className="fixed bottom-4 left-4 p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition duration-150 ease-in-out hidden md:block"
                    >
                        <ArrowRightFromLine size={20} />
                    </button>
                )}
            </div>
            <LoginModal isOpen={isLoginModalOpen} onOpen={openLoginModal} onClose={closeLoginModal} />
        </>
    );
};

export default ChatPage;