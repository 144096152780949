
import { useEffect, useState } from 'react';
import axios from '../../utils/axios';

const SwitchingBabyEmoji = () => {
    const babyEmojis = [
        '👶',
        '👶🏻',
        '👶🏼',
        '👶🏽',
        '👶🏾',
        '👶🏿'
    ];

    const [currentEmojiIndex, setCurrentEmojiIndex] = useState(0);
    const [isReversing, setIsReversing] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentEmojiIndex((prevIndex) => {
                if (!isReversing) {
                    if (prevIndex === babyEmojis.length - 1) {
                        setIsReversing(true);
                        return prevIndex - 1;
                    }
                    return prevIndex + 1;
                } else {
                    if (prevIndex === 0) {
                        setIsReversing(false);
                        return prevIndex + 1;
                    }
                    return prevIndex - 1;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [isReversing]);

    return (
        <div className="relative w-24 h-24 bg-teal-100 dark:bg-teal-800 rounded-full mb-6 flex items-center justify-center">
            {babyEmojis.map((emoji, index) => (
                <span
                    key={index}
                    className={`absolute transition-opacity duration-500 ${index === currentEmojiIndex ? 'opacity-100' : 'opacity-0'} text-5xl`}
                >
                    {emoji}
                </span>
            ))}
        </div>
    );
};

const ChatEmptyState = ({ handleConversationStarterClick }) => {
    const [prompts, setPrompts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchPrompts = async () => {
            try {
                const response = await axios.get('/conversation/prompts');
                setPrompts(response.data);
            } catch (error) {
                console.error('Error fetching prompts:', error);

            }
            setIsLoading(false);
        };

        fetchPrompts();
    }, []);

    return (

        <div className="flex flex-col items-center justify-center min-h-full text-center">
            <SwitchingBabyEmoji />
            <h2 className="text-2xl font-bold mb-4 text-teal-500 dark:text-teal-300">
                Welcome to Preggo AI
            </h2>
            <p className="mx-4 text-sm md:text-base mb-4 lg:mb-12 max-w-xl text-gray-600 dark:text-gray-300">
                Your personalized pregnancy companion ready to support you every step of the way. The more you tell me about your pregnancy, the more I can help you!
            </p>
            <div className="pl-4 md:pr-4 w-full flex md:justify-center overflow-x-scroll overflow-y-hidden space-x-4 lg:space-x-8 scrollbar-none py-4">
                {isLoading ? (
                    [1, 2, 3].map((_, index) => (
                        <div className="w-2/3 md:w-1/4 border border-gray-400 bg-gray-400 dark:border-gray-600 dark:bg-gray-600 px-4 py-4 h-20 rounded-2xl flex-shrink-0 animate-pulse" />
                    ))
                ) : (
                    prompts.map((starter, index) => (
                        <div
                            key={index}
                            onClick={() => handleConversationStarterClick(starter)}
                            className="w-2/3 md:w-1/4 cursor-pointer border border-gray-400 dark:border-gray-600 text-gray-700 dark:text-gray-300 px-4 py-4 h-20 rounded-2xl hover:bg-gray-100 dark:hover:bg-gray-700 transition duration-300 text-sm text-left flex-shrink-0 flex items-center"
                        >
                            {starter}
                        </div>
                    ))
                )}
            </div>

        </div>
    );
};

export default ChatEmptyState;