import React, { useState, useEffect, useRef } from 'react';
import { ArrowUp, Loader2 } from 'lucide-react';
import axios from '../../utils/axios';
import ChatEmptyState from './ChatEmptyState';

const ChatInterface = ({ conversationId, onNewConversation }) => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const textareaRef = useRef(null);
    const [conversation, setConversation] = useState(null);

    useEffect(() => {
        if (conversationId) {
            fetchConversation(conversationId);
        } else {
            setMessages([]);
        }
    }, [conversationId]);

    const fetchConversation = async (conversationId) => {
        try {
            const response = await axios.get(`/conversation/${conversationId}`);
            setMessages(response.data.messages);
            setConversation(response.data);
        } catch (error) {
            console.error('Error fetching conversation:', error);
        }
    };

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [inputMessage, messages]);

    const handleSendMessage = async (e, starterMessage = null) => {
        if (e) e.preventDefault();
        const messageToSend = starterMessage || inputMessage.trim();
        if (!messageToSend) return;

        const tempMessage = { role: 'user', content: messageToSend, tempId: Date.now() };

        // Optimistically update the UI
        setMessages(prevMessages => [...prevMessages, tempMessage]);
        if (!starterMessage) setInputMessage('');
        textareaRef.current.focus();
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

        setIsLoading(true);
        try {
            let response;
            if (conversation && conversation._id) {
                response = await axios.post(`/conversation/${conversation._id}/messages`, { message: messageToSend });
            } else {
                response = await createNewConversation(messageToSend);
                setConversation(response.data.conversation || response.data);
                onNewConversation(response.data._id);
            }
            setMessages(prevMessages => [
                ...prevMessages.filter(msg => msg.tempId !== tempMessage.tempId),
                ...response.data.messages
            ]);
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const createNewConversation = async (initialMessageText) => {
        try {
            const response = await axios.post(`/conversation`, { message: initialMessageText });
            return response;
        } catch (error) {
            console.error('Error creating new conversation:', error);
            throw error;
        }
    };

    const handleConversationStarterClick = (starter) => {
        handleSendMessage(null, starter);
    };

    const renderMessageWithMarkdown = (text) => {
        return <div>{text}</div>;
    };

    const formatTextWithStyles = (text) => {
        const lines = text.split('\n');
    
        return lines.map((line, lineIndex) => {
            const parts = line.split(/(\*\*[^*]+\*\*|\*[^*]+\*|_[^_]+_|`[^`]+`)/);
    
            return (
                // Not last, ad mb-2
                <p className="mb-2 last:mb-0" key={lineIndex}>
                    {parts.map((part, partIndex) => {
                        if (part.startsWith('**') && part.endsWith('**')) {
                            return (
                                <strong className="font-bold" key={partIndex}>
                                    {part.slice(2, -2)}
                                </strong>
                            );
                        } else if (part.startsWith('*') && part.endsWith('*')) {
                            return (
                                <em className="italic" key={partIndex}>
                                    {part.slice(1, -1)}
                                </em>
                            );
                        } else if (part.startsWith('_') && part.endsWith('_')) {
                            return (
                                <span className="underline" key={partIndex}>
                                    {part.slice(1, -1)}
                                </span>
                            );
                        } else if (part.startsWith('`') && part.endsWith('`')) {
                            return (
                                <code className="bg-gray-200 rounded px-1 text-gray-800 dark:bg-gray-800 dark:text-gray-200" key={partIndex}>
                                    {part.slice(1, -1)}
                                </code>
                            );
                        } else {
                            return part;
                        }
                    })}
                </p>
            );
        });
    };
    

    return (
        <div className="flex flex-col h-full bg-white dark:bg-gray-800">
            <div className="flex-1 overflow-y-auto lg:p-20 space-y-4">
                {messages.length === 0 ? (
                    <ChatEmptyState handleConversationStarterClick={handleConversationStarterClick} />
                ) : (
                    <div className="px-4 py-4 space-y-4">
                        {messages.map((message, index) => (
                            <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                                <div className={`max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-2xl p-3 ${message.role === 'user'
                                        ? 'bg-teal-500 text-white'
                                        : 'bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200'
                                    }`}>
                                    {formatTextWithStyles(message.content)}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                {isLoading && (
                    <div className="px-4 flex justify-start">
                        <div className="rounded-2xl p-3 bg-gray-100 dark:bg-gray-700">
                            <Loader2 className="animate-spin text-white" />
                        </div>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>
            <div className="p-4 flex justify-center">
                <form onSubmit={handleSendMessage} className="w-full max-w-2xl">
                    <div className="relative">
                        <textarea
                            ref={textareaRef}
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            placeholder="Type your message..."
                            rows="1"
                            className={`w-full py-3 px-4 pr-12 rounded-3xl outline-none focus:outline-none focus:ring-2 focus:ring-teal-600 resize-none border shadow-lg border-gray-300 dark:border-gray-500 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 placeholder-gray-800 dark:placeholder-gray-300`}
                            style={{ minHeight: '52px', maxHeight: '150px' }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSendMessage(e);
                                }
                            }}
                        />
                        <button
                            type="submit"
                            disabled={isLoading || inputMessage.trim().length === 0}
                            className="absolute right-2 bottom-3.5 bg-teal-500 text-white p-2 rounded-full hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 disabled:opacity-50 disabled:cursor-default disabled:hover:bg-teal-500"
                        >
                            {isLoading ? <Loader2 size={20} className="animate-spin" /> : <ArrowUp size={20} />}
                        </button>
                    </div>
                    <div className="text-xs text-gray-600 dark:text-gray-400 mt-2 text-center">
                        Preggo AI can make mistakes. Please consult with your healthcare provider for medical advice.
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChatInterface;
