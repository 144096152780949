import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../utils/axios';
import { Loader2, AlertCircle } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

const VerifyAuthPage = () => {
  const [status, setStatus] = useState('Verifying...');
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useAuth();

  useEffect(() => {
    const verifyToken = async () => {
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');

      if (!token) {
        setStatus('Invalid token');
        setIsError(true);
        return;
      }

      try {
        const response = await axiosInstance.get(`/auth/verify?token=${token}`);
        
        if (response.data.msg === 'Login successful') {
          setUser(response.data.user);
          setStatus('Login successful. Redirecting...');
          setTimeout(() => navigate('/chat'), 2000);
        } else {
          setStatus('Verification failed. Please try again.');
          setIsError(true);
        }
      } catch (error) {
        console.error('Verification error:', error);
        setStatus(error.response?.data?.msg || 'An error occurred. Please try again.');
        setIsError(true);
      }
    };

    verifyToken();
  }, [location, navigate, setUser]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-800 px-4">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-6">
          Verifying Your Login
        </h1>
        <div className="flex items-center justify-center space-x-4 mb-8">
          {isError ? (
            <AlertCircle size={32} className="text-red-500 dark:text-red-400" />
          ) : (
            <Loader2 size={32} className="text-teal-600 dark:text-teal-400 animate-spin" />
          )}
          <span className="text-xl text-gray-700 dark:text-gray-300">{status}</span>
        </div>
        {isError && (
          <button
            onClick={() => navigate('/login')}
            className="px-6 py-3 text-base font-medium text-white bg-teal-600 rounded-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 dark:focus:ring-offset-gray-800 transition-colors duration-200"
          >
            Back to Login
          </button>
        )}
      </div>
    </div>
  );
};

export default VerifyAuthPage;