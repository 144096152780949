import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import googleLogo from '../assets/icon-google-sign-in.svg';
import appleLogo from '../assets/icon-apple-sign-in-light.svg';
import axios from '../utils/axios';

const MagicLinkSent = ({ email, onBack }) => {
  return (
    <div className="text-center">
      <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">Check your email</h3>
      <p className="text-gray-600 dark:text-gray-400 mb-4">
        We've sent a magic link to <strong>{email}</strong>
      </p>
      <p className="text-gray-600 dark:text-gray-400 mb-6">
        Click the link in the email to sign in to your account.
      </p>
      <button
        onClick={onBack}
        className="text-teal-600 hover:text-teal-700 dark:text-teal-400 dark:hover:text-teal-300 font-medium"
      >
        ← Back to login
      </button>
    </div>
  );
};

const LoginModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showMagicLinkSent, setShowMagicLinkSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      const response = await axios.post('/auth/login', { email });
      setMessage(response.data.msg);
      setShowMagicLinkSent(true);
    } catch (error) {
      setMessage(error.response?.data?.msg || 'An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    setShowMagicLinkSent(false);
    setEmail('');
    setMessage('');
  };

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 z-50 overflow-y-auto" onClose={() => {}}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 dark:bg-opacity-50" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl">
              {showMagicLinkSent ? (
                <MagicLinkSent email={email} onBack={handleBack} />
              ) : (
                <>
                  <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900 dark:text-white text-center mt-4 mb-10">
                    Let's get started
                  </Dialog.Title>
                  <div className="mt-4">
                    <button className="w-full py-2 mb-3 text-sm font-semibold text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 dark:focus:ring-offset-gray-800 flex items-center justify-center space-x-2">
                      <img src={googleLogo} alt="Google Logo" className="w-6 h-6" />
                      <span>Sign in with Google</span>
                    </button>
                    <button className="w-full py-2 mb-3 text-sm font-semibold text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 rounded-md hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 dark:focus:ring-offset-gray-800 flex items-center justify-center space-x-2">
                      <img src={appleLogo} alt="Apple Logo" className="w-6 h-6" />
                      <span>Sign in with Apple</span>
                    </button>
                    <div className="text-center text-sm text-gray-600 dark:text-gray-400 py-2">or</div>
                    <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                      <div>
                        <input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email address"
                          className="mt-1 block w-full px-3 py-2 text-sm bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-teal-500 dark:focus:ring-teal-400 focus:border-teal-500 dark:focus:border-teal-400 dark:text-white dark:placeholder-gray-300"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <button
                        type="submit"
                        className="w-full px-4 py-2 text-sm font-medium text-white bg-teal-600 rounded-md hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 dark:focus:ring-offset-gray-800"
                        disabled={isLoading}
                      >
                        {isLoading ? 'Sending...' : 'Continue with Email'}
                      </button>
                    </form>
                    {message && (
                      <p className="mt-4 text-sm text-center text-gray-600 dark:text-gray-400">
                        {message}
                      </p>
                    )}
                  </div>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LoginModal;